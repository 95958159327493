.owner {
  padding: 0px 17px 4px 0px;
  margin: 5px;
  color: black;
  border-radius: var(--rounding);
  float: left;
}

.ownertext {
  font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
margin-left: 5px;
}
