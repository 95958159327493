.Chip {
  padding: 4px 17px 4px 17px;
  margin: 5px;
  background: var(--secondary-color);;
  color: white;
  border-radius: var(--rounding);
  float: left;
}
.ChipText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
